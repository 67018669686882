<template>
  <div class="error">
    <van-image class="error-head"
               :src="$config.errorLogo"/>

  </div>
</template>

<script>

export default {
  name:'404',
  components: {
  },
  setup() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../common/style/base/base';
  .seting-box {
    .save-btn {
      width: 80%;
      margin: 20px auto ;
    }
  }
  .error{
    background: #ffffff;
    .error-head{
      display: block;
      position: absolute;
      top: 22%;
      left: 47.5px;
      .wh(280px,280px);
      margin: 0 auto;
    }
  }

</style>
